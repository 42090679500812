<template>
  <section id="vendor-list-module">
    <div class="row">
      <div class="col-12 d-flex align-items-center">
        <h6 class="my-3">Ecosystem vendors list</h6>

        <b-button variant="primary" class="ml-auto" to="/care-ecosystem/vendors/new">
          <feather type="plus" />
          New vendor
        </b-button>
      </div>
    </div>
    <table class="table mx-0 mb-2">
      <thead class="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Vendor name</th>
        <th scope="col">
          <div class="d-flex align-items-center">
            Is Partner
            <div class="ml-2">
              <feather
                v-b-tooltip.hover
                class="text-primary"
                title="A Sword Partner is a vendor that Sword has a contract with and has clients coming through it."
                type="info"/>
          </div>
          </div>
        </th>
        <th scope="col">Has active Clients</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
        <template v-if="Array.isArray(vendors) && vendors.length">
          <tr v-for="(vendor, vendorIndex) in vendors" :key="vendorIndex">
            <td class="align-middle"> {{ vendor.id }}</td>
            <td class="align-middle"> {{ vendor.name }}</td>
            <td class="align-middle">
              {{ vendor.is_partner ? 'Yes' : 'No' }}
            </td>
            <td class="align-middle"> {{ vendor.has_active_clients ? 'Yes' : 'No' }}</td>
            <td class="align-middle table__item-actions">
              <b-button v-b-tooltip.hover
                        title="Edit"
                        size="sm"
                        variant="light"
                        @click="editVendor(vendor.id)">
                <feather type="edit-2"></feather>
              </b-button >
              <b-button v-b-tooltip.hover
                        size="sm"
                        title="Delete"
                        variant="light"
                        @click="safeDeleteVendor(vendor)">
                <feather type="trash"></feather>
              </b-button>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" class="text-center">No vendors added, add one using the “New vendor“ button above</td>
        </tr>
      </tbody>
    </table>
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loadingPage"
                      @change="changePage"
                      v-model="pagination.currentPage"
                      :total-rows="pagination.totalRows"
                      :per-page="pagination.itemsPerPage"
                      size="md">
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import pagination from '@/mixins/lists/pagination';

export default {
  name: 'VendorList',
  mixins: [ pagination ],
  data() {
    return {
      vendors: [],
      loadingPage: false,
    };
  },
  mounted() {
    this.fetchVendors();
  },
  methods: {
    async safeDeleteVendor(vendor) {
      const canBeDeleted = await this.confirmVendorDeletion(vendor);
      return canBeDeleted ? this.deleteVendor(vendor) : false;
    },
    async confirmVendorDeletion(vendor) {
      try {
        return await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete vendor ${vendor.name} ?`);
      } catch {
        console.error('Modal destroyed without response');
        return false;
      }
    },
    deleteVendor(vendor) {
      axios.delete(`v1/care-ecosystem/vendors/${vendor.id}`).then(() => {
        this.$noty.success(`Vendor ${vendor.name} deleted successfully`);
        this.fetchVendors();
      }).catch(err => {
        this.$noty.error(`Something went wrong deleting vendor '${vendor.name}'`);
        console.error(`Failed to delete vendor ${vendor.name}: `, err);
      });
    },
    onPageChange() {
      this.fetchVendors();
    },
    fetchVendors() {
      this.loadingPage = true;
      const page = this.pagination.currentPage - 1;
      const params = {
        limit: this.pagination.itemsPerPage,
        offset: page * this.pagination.itemsPerPage,
      };

      axios.get('v1/care-ecosystem/vendors', { params }).then(response => {
        this.vendors = response.data.entries;
        this.pagination.totalRows = response.data.total;
      }).catch(err => {
        this.$noty.error('Failed to fetch care ecosystem vendors: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    editVendor(id) {
      this.$router.push({ name: 'CareEcosystemVendorsEdit', params: { id } });
    },
  },
};
</script>

<style lang="scss">
.table {
  table-layout: fixed;

  &__item {
    &:hover {
      cursor: pointer;
      background-color: $context-color-7;
    }

    td {
      align-items: center;
      @extend %compact-text;
    }

    &-actions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.25rem;
      text-overflow: initial !important;

      &-button {
        min-width: initial !important;
      }
    }
  }
}
</style>
